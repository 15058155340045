<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Trial Balance</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="pdf" class="btn btn-secondary"                   
                    @click="pdfClick()">
                        <i class="fa fa-file-pdf-o"></i> PDF
                    </button>&nbsp;
                    <button type="button" class= "btn btn-primary" v-on:click="exportExcel">
                        <i class="fa fa-file-excel-o"></i> Excel
                    </button>
                </div>

                <kendo-grid
                    ref="grid" 
                    :data-source="TrialBalance"
                    :filterable="filterableConfig"
                    :sortable="true"
                    :pageable="pageableConfig"
                    :columns="columns"
                    :resizable="true"
                    :editable="false"
                    >
                </kendo-grid>

                <trial-balance-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import moment from 'moment';
import trialBalancePDF from '../../../../assets/template/TrialBalanceReportPdfTemplate.vue'
import trialBalanceReportService from '../Script/TrialBalanceReportService.js';
import { response } from '../../../../infrastructure/constant/response';
import { kendo_grid, datetime_zone, trial_balance } from '../../../../infrastructure/constant/variable';

export default {
    name: 'TrialBalanceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'trial-balance-pdf' : trialBalancePDF,
    },
    async created() {
        var data = await trialBalanceReportService.getBalanceSettingData(this.SettingId);
        this.SettingData = this.$globalfunc.objectToArrayConverter(data, "Accounting-SettingBalance");
    },
    computed: {
        TrialBalance () {
            var startDate = this.DateFrom;
            var endDate = this.DateTo;

            if(this.SettingData.length <= 0){
                return [];
            }else{
                var variablesNew = {
                    "startDate" : startDate + datetime_zone.date_from,
                    "endDate" : endDate + datetime_zone.date_to,
                    "setting" : this.SettingData,
                    "status" : trial_balance.status
                }
                
                return new kendo.data.DataSource({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                return { 
                                    query: trialBalanceReportService.readTrialBalanceQuery(),
                                    variables : variablesNew
                                };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetTrialBalanceUnnested == null){
                                return [];
                            }else{
                                return response.data.GetTrialBalanceUnnested;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetTrialBalanceUnnested == null){
                                return 0;
                            }else{
                                return response.data.GetTrialBalanceUnnested.length;
                            }
                        }
                    },
                    group: [{ field: "head_name"}, { field: "group_name"}],
                })
            }
        }
    },
    data(){
        return{
            DateFrom: this.$route.params.datefrom,
            DateTo: this.$route.params.dateto,
            SettingId: this.$route.params.settingid,
            
            columns:  [
                { field: "head_name", title: "Judul", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "group_name", title: "Grup Akun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "chart_of_account_id", title: "Akun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "chart_of_account_name", title: "Nama Akun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "initial_balance", title: "Saldo Awal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(initial_balance, 'n') #" },
                { field: "balance", title: "Saldo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(balance, 'n') #" },
                { field: "credit", title: "Kredit", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(credit, 'n') #" },
                { field: "debit", title: "Debit", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(debit, 'n') #" },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,

            SettingData: [],
        }
    },
    methods: {
        pdfClick() {
            this.$router.push({ name: 'Trial Balance Report Html Template', params: { datefrom: this.DateFrom , dateto: this.DateTo, settingid: this.SettingId } });
        },
        async exportExcel () {
            var variables = {
                startDate : this.DateFrom,
                endDate : this.DateTo,
                setting : this.SettingData
            };
            var gridData = this.$refs.grid.kendoWidget().dataSource._data;
            var data = trialBalanceReportService.trialBalanceReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else{
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_Neraca_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = trialBalanceReportService.trialBalanceReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
    }
}
</script>

<style scoped>
</style>